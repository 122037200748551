export const languages = [
  {
    id: "Bengali",
    text: "ধন্যবাদ",
    progress: 99,
  },
  {
    id: "English",
    text: "Thanks",
    progress: 50,
  }
]
